// vue
import { ref } from 'vue'

// nuxt
import { useNuxtApp } from '#app'

// pinia
import { defineStore } from 'pinia'

// types
import type { GradeLevel } from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

export const useGradeLevelsStore = defineStore('grade-levels', () => {
  // fetch repositories
  const { $gradeLevels } = useNuxtApp()

  // state
  const gradeLevels = ref<GradeLevel[]>([])

  // actions
  async function index (config?: NitroFetchOptions<string>) {
    const _gradeLevels =
      await $gradeLevels.index<GradeLevel[]>(config)
    gradeLevels.value = _gradeLevels || []
    return gradeLevels
  }

  return {
    gradeLevels,
    index
  }
})
